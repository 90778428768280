import { call, put, takeEvery } from "redux-saga/effects";

// Enrolment Redux States
import { INIT_ENROLMENT_LIST } from "./actionTypes";
import { enrolmentListFailed, enrolmentListSuccess } from "./actions";

//Include Both Helper File with needed methods
import {
  getEnrolmentList
} from "../../helpers/backend_helper";

function* enrolmentList() {
  try {
    const response = yield call(getEnrolmentList);
    if(response && response.statusCode == 200){
      yield put(enrolmentListSuccess(response));
    }else{
      yield put(enrolmentListFailed(response?.message));
    }
  } catch (error) {
    yield put(enrolmentListFailed(error));
  }
}

function* enrolmentSaga() {
  yield takeEvery(INIT_ENROLMENT_LIST, enrolmentList);
}

export default enrolmentSaga;
