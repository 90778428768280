//LOGIN
export const POST_JWT_LOGIN = "auth/admin/login";

// ENROLMENT
export const ENROLMENT_LIST = "enrolment/list";

// QUIZ
export const QUIZ_LIST = "quiz/list";
export const QUIZ_CREATE = "quiz/create";
export const QUIZ_UPDATE = "quiz/update";
export const QUIZ_VIEW = "quiz/view";

// QUESTION
export const QUESTION_LIST = "question/list";
export const QUESTION_ADD = "question/addnew";
export const QUESTION_UPDATE = "question/update";

// CURRICULUM
export const CURRICULUM_LIST = "curriculum/list";
export const CREATE_CURRICULUM = "curriculum/create";
export const VIEW_CURRICULUM = "curriculum/view";
export const EDIT_CURRICULUM = "curriculum/edit";

// REGISTRATION
export const REGISTRATION_LIST = "registration/list";
export const NEW_REGISTRATION = "registration/new-registration";

//Master Set List
export const MASTER_SET_LIST = "master_set/list";

//Master Set Add
export const MASTER_SET_ADD = "master_set/addnew";

//Master Set Update
export const MASTER_SET_UPDATE = "master_set/update";

//Daily Quiz List
export const DAILY_QUIZ_LIST = "quiz/daily/list";

//Daily Quiz Create
export const DAILY_QUIZ_CREATE = "quiz/daily/create";

//Daily Quiz Update
export const DAILY_QUIZ_UPDATE = "quiz/daily/update";