import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Badge, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, FormFeedback, Collapse, ListGroup, ListGroupItem } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { initQuestionList, initQuizCreate, initQuizList, initQuizUpdate, initQuizView } from "store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";

import classnames from "classnames";

const DatatableTables = () => {
    const dispatch = useDispatch();
    const { Quiz } = useSelector(state => {
        return state;
    });

    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    });
    const [modal_large, setmodal_large] = useState(false);
    const [addMode, setAddMode] = useState(true);
    const [questions, setQuestion] = useState([]);

    const [col1, setCol1] = useState(false);

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        dispatch(initQuizList())
    }, []);

    useEffect(() => {
        if (col1) {
            dispatch(initQuestionList({listtype:"questions"}));
        }
    }, [col1]);

    const t_col1 = () => {
        setCol1(!col1);
    }

    const updateQuiz = (id, type, data) => {
        if (type === "publish") {
            dispatch(initQuizUpdate({ 'isPublished': data, 'quizId': id }));
        } else {
            dispatch(initQuizUpdate({ 'status': data, 'quizId': id }));
        }
    }
    useEffect(() => {
        if (Quiz.quizUpdate.data.statusCode === 200) {
            dispatch(initQuizList());
        }
    }, [Quiz.quizUpdate.data.statusCode]);

    const openQuizUpdateModal = (quizId) => {
        dispatch(initQuizView({quizId, type: "edit"}));
        setmodal_large(!modal_large);
        removeBodyCss();
        setAddMode(false);
    }

    useEffect(() => {
        if (Quiz.quizList.data.quiz?.length) {
            const modifiedQuizData = Quiz.quizList.data.quiz?.map(e => {
                e["isPublished"] = e["isPublished"] === true ? <Badge onClick={() => updateQuiz(e["id"], "publish", 0)} style={{ fontSize: 13 }} className="bg-success">
                    Live
                </Badge> : <Badge onClick={() => updateQuiz(e["id"], "publish", 1)} className="bg-info" style={{ fontSize: 13 }}>
                    Ready to Live
                </Badge>;
                e["status"] = e["status"] === true ? <Badge onClick={() => updateQuiz(e["id"], "status", 0)} className="bg-success" style={{ fontSize: 13 }}>
                    Active
                </Badge> : <Badge onClick={() => updateQuiz(e["id"], "status", 1)} className="bg-danger" style={{ fontSize: 13 }}>
                    Inactive
                </Badge>;
                e["action"] = <Button color="link" onClick={() => openQuizUpdateModal(e["id"])} className="btn btn-link waves-effect btn-sm">Edit</Button>
                e["createdAt"] = new Date(e.createdAt).toDateString();
                e["updatedAt"] = new Date(e.updatedAt).toDateString();
                return e;
            });
            setTableData({
                columns: [{
                    label: "Quiz Title",
                    field: "quizTitle",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Target Course",
                    field: "targetCourse",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Is Live",
                    field: "isPublished",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Quiz Added On",
                    field: "createdAt",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Last Updated",
                    field: "updatedAt",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Actions",
                    field: "action",
                    sort: "asc",
                    width: 150,
                }],
                rows: modifiedQuizData
            })
        }
    }, [Quiz.quizList.data.quiz]);

    const tog_large = () => {
        setmodal_large(!modal_large);
        removeBodyCss();
        setAddMode(true);
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            quizTitle: '',
            description: '',
            targetCourse: '',
            isPublished: '',
            status: '',
            questions: []
        },
        validationSchema: Yup.object({
            quizTitle: Yup.string().required("Please Enter Quiz Title"),
            description: Yup.string().required("Please Enter Description"),
            targetCourse: Yup.string().required("Please Enter Target Course Name")
        }),
        onSubmit: (values) => {
            dispatch(initQuizCreate({ ...values, questions }))
        }
    });

    useEffect(()=>{
        if(Quiz.quizView.data.quiz?.id){
            validation.setValues({
                quizTitle: Quiz.quizView.data.quiz.quizTitle,
                description: Quiz.quizView.data.quiz.description,
                targetCourse: Quiz.quizView.data.quiz.targetCourse,
                isPublished: Quiz.quizView.data.quiz.isPublished,
                status: Quiz.quizView.data.quiz.status,
                questions: Quiz.quizView.data.quiz.questions
            })
        }
    }, [Quiz.quizView.data.quiz])

    const onChangeQuestion = (e, id) => {
        let checkedQuestions = []
        if (e.target.checked)      //if checked (true), then add this id into checkedList
        {
            checkedQuestions = questions.filter(CheckedId =>
                CheckedId !== id
            )
            checkedQuestions.push(id)
        }
        else                    //if not checked (false), then remove this id from checkedList
        {
            checkedQuestions = questions.filter(CheckedId =>
                CheckedId !== id
            )
        }
        setQuestion(checkedQuestions);
    }

    document.title = "Quiz | Admin | Kanha Computer Academy";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between">
                        <Breadcrumbs title="Quiz List" />
                        <Button className="waves-effect waves-light btn btn-dark" onClick={tog_large}>Add New Quiz</Button>
                    </div>

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {Quiz.quizList.loading &&
                                        <div className="text-center">
                                            <div className="p-5 d-flex justify-content-center">
                                                <Spinner className="ms-2" color="dark" />
                                            </div>
                                            <div>Please wait while loading data...</div>
                                        </div>
                                    }
                                    {!Quiz.quizList.loading &&
                                        <MDBDataTable noBottomColumns responsive bordered data={tableData} />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            
            <Modal isOpen={modal_large} toggle={tog_large} size="lg" scrollable>
                <ModalHeader className="mt-0" toggle={tog_large}>{addMode ? "Add new quiz" : "Edit quiz"}</ModalHeader>
                <ModalBody>
                    <Form
                        className="row mt-2 g-3 needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationQuizTitle">Quiz Title</Label>
                                    <Input
                                        name="quizTitle"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationQuizTitle"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.quizTitle || ""}
                                        invalid={
                                            validation.touched.quizTitle &&
                                                validation.errors.quizTitle
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.quizTitle &&
                                        validation.errors.quizTitle ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.quizTitle}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationDescription">Description</Label>
                                    <Input
                                        name="description"
                                        placeholder=""
                                        type="textarea"
                                        className="form-control"
                                        id="validationDescription"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ""}
                                        invalid={
                                            validation.touched.description &&
                                                validation.errors.description
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.description &&
                                        validation.errors.description ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.description}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationTargetCourse">Target Course</Label>
                                    <div className="input-group has-validation">
                                        <Input
                                            name="targetCourse"
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            id="validationTargetCourse"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.targetCourse || ""}
                                            invalid={
                                                validation.touched.targetCourse &&
                                                    validation.errors.targetCourse
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.targetCourse &&
                                            validation.errors.targetCourse ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.targetCourse}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                <div className="accordion" id="accordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className={classnames({
                                                collapsed: col1 === false
                                            }, "accordion-button fw-semibold border")} type="button" onClick={t_col1} style={{ cursor: "pointer", backgroundColor: "#eee" }}>
                                                Add Questions
                                            </button>
                                        </h2>

                                        <Collapse isOpen={col1} className="accordion-collapse">
                                            <div className="accordion-body p-0 border">
                                                <ListGroup flush>
                                                    {Quiz.questionList.loading && <ListGroupItem
                                                        disabled
                                                        href="#"
                                                        tag="a"
                                                    >
                                                        Loading...
                                                    </ListGroupItem>}
                                                    {!Quiz.questionList.loading &&
                                                        Quiz.questionList.data.questions?.map(elem => {
                                                            return <ListGroupItem
                                                                key={elem.id}
                                                                href="#"
                                                                tag="a"
                                                            >
                                                                <div className="d-flex">
                                                                    <div>
                                                                        <Input
                                                                            id={"id_" + elem.id}
                                                                            name="check"
                                                                            type="checkbox"
                                                                            value={elem.id}
                                                                            onChange={(e) => onChangeQuestion(e, elem.id)}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <Label for={"id_" + elem.id} className="px-2 m-0">
                                                                            {elem.question}
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </ListGroupItem>
                                                        })
                                                    }
                                                </ListGroup>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="12">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-dark" type="submit">Create</button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default DatatableTables;
