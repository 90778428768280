import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Badge, Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, FormFeedback, Form } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { initMasterQuestionList, initQuestionAdd, initQuestionSetList, initQuestionUpdate } from "store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

const Questions = () => {
    const dispatch = useDispatch();
    const { questionListState, questionAddState, questionUpdateState} = useSelector(state => {
        return state.Question;
    });

    const location = useLocation();
    const actionParam = new URLSearchParams(location.search).get("action");
    
    const { masterSetList} = useSelector(state => {
        return state.MasterSet;
    });

    useEffect(() => {
        dispatch(initMasterQuestionList())
        dispatch(initQuestionSetList({ listtype: "menu" }))
    }, []);

    useEffect(()=>{
        if(actionParam == "add"){
            newQuestion()
        }
    }, [actionParam])

    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    });

    const [forDailyQuiz, setQuestionType] = useState(false);
    const [questionId, setQuestionId] = useState(null);

    useEffect(() => {
        if (questionListState?.data?.questions?.length) {
            const modifiedQuizData = questionListState?.data?.questions?.map(e => {
                e["status"] = e["status"] === true ? <Badge onClick={() => updateQuestion(e["id"], "status", 0)} className="bg-success" style={{ fontSize: 13 }}>
                    Active
                </Badge> : <Badge onClick={() => updateQuestion(e["id"], "status", 1)} className="bg-danger" style={{ fontSize: 13 }}>
                    Inactive
                </Badge>;

                e["isUsed"] = e["isUsed"] === true ? <Badge className="bg-success" style={{ fontSize: 13 }}> In Used </Badge> : <Badge className="bg-danger" style={{ fontSize: 13 }}> Not In Use </Badge>;

                // e["tags"] = (e["tags"]) && ((e["tags"]).split(",")).map(elem => <Badge key={elem} className="bg-danger m-1">{elem}</Badge>)
                e["action"] = <Button color="link" onClick={() => openQuestionUpdateModal(e)} className="btn btn-link waves-effect btn-sm">Edit</Button>
                e["createdAt"] = new Date(e.createdAt).toDateString();
                e["updatedAt"] = new Date(e.updatedAt).toDateString();
                e["setName"] = e["masterSet"] ? e["masterSet"]["setName"] : "-"
                return e;
            });
            setTableData({
                columns: [{
                    label: "Question",
                    field: "question",
                    sort: "asc",
                    width: 250,
                },
                {
                    label: "Explanation",
                    field: "explanation",
                    sort: "asc",
                    width: 780,
                },
                {
                    label: "Correct Answer",
                    field: "correctAnswer",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Option A",
                    field: "optionA",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Option B",
                    field: "optionB",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Option C",
                    field: "optionC",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Option D",
                    field: "optionD",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Tags",
                    field: "tags",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Set Name",
                    field: "setName",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Is USed",
                    field: "isUsed",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Created At",
                    field: "createdAt",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Updated At",
                    field: "updatedAt",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Actions",
                    field: "action",
                    sort: "asc",
                    width: 150,
                }],
                rows: modifiedQuizData
            })
        }
    }, [questionListState?.data?.questions]);

    const [modal_large, setmodal_large] = useState(false);
    const [addMode, setAddMode] = useState(true);

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const newQuestion = () => {
        validation.resetForm()
        setQuestionType(false)
        setmodal_large(!modal_large);
        removeBodyCss();
        setAddMode(true);
    }

    const openQuestionUpdateModal = (data) => {
        setQuestionId(data["id"]);

        validation.setValues({
            question: data["question"],
            optionA: data["optionA"],
            optionB: data["optionB"],
            optionC: data["optionC"],
            optionD: data["optionD"],
            correctAnswer: data["correctAnswer"],
            explanation: data["explanation"],
            tags: data["tags"]
        });

        setmodal_large(!modal_large);
        removeBodyCss();
        setAddMode(false);
    }

    useEffect(() => {
        if (questionAddState?.data?.message == "Question added successfully") {
            setmodal_large(!modal_large)
            dispatch(initMasterQuestionList())
        }
    }, [questionAddState])

    useEffect(() => {
        if (questionUpdateState?.data?.message == "Question updated successfully") {
            setmodal_large(!modal_large)
            dispatch(initMasterQuestionList())
        }
    }, [questionUpdateState])

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            question: '',
            optionA: '',
            optionB: '',
            optionC: '',
            optionD: '',
            correctAnswer: '',
            explanation: '',
            tags: '',
            setId: '',
            forDailyQuiz: forDailyQuiz
        },
        validationSchema: Yup.object({
            question: Yup.string().required("Please enter question"),
            optionA: Yup.string().required("Please enter option A"),
            optionB: Yup.string().required("Please enter option B"),
            optionC: Yup.string().required("Please enter option C"),
            optionD: Yup.string().required("Please enter option D"),
            correctAnswer: Yup.string().required("Please enter correct answer"),
            explanation: Yup.string().required("Please enter explanation"),
            tags: Yup.string().required("Please enter tags"),
            forDailyQuiz: Yup.boolean().optional(),
            setId: forDailyQuiz === true ? Yup.number().required("Set id is required") : Yup.number().optional()
        }),
        onSubmit: (values) => {
            if(addMode){
                dispatch(initQuestionAdd({ ...values }))
            }else{
                values["questionId"] = questionId;
                dispatch(initQuestionUpdate({...values}))
            }
        }
    });

    const onChangeQuestionType = (e) => {
        if (e.target.value == "academyQuiz") {
            setQuestionType(false)
        } else setQuestionType(true);
    }

    document.title = "Questions | Admin | Kanha Computer Academy";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between">
                        <Breadcrumbs title="Master Question" />
                        <Button className="waves-effect waves-light btn btn-dark" onClick={newQuestion}>Add New Question</Button>
                    </div>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {questionListState.loading &&
                                        <div className="text-center">
                                            <div className="p-5 d-flex justify-content-center">
                                                <Spinner className="ms-2" color="dark" />
                                            </div>
                                            <div>Please wait while loading data...</div>
                                        </div>
                                    }
                                    {!questionListState.loading &&
                                        <MDBDataTable noBottomColumns responsive bordered data={tableData} />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal isOpen={modal_large} toggle={newQuestion} size="lg" scrollable>
                <ModalHeader className="mt-0" toggle={newQuestion}>{addMode ? "Add new question" : "Edit question"}</ModalHeader>
                <ModalBody>
                    <Form
                        className="row mt-2 g-3 needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            {addMode &&
                                <Col md="12">
                                    <FormGroup>
                                        <legend>
                                            Question Type
                                        </legend>
                                        <div className="d-flex align-items-center justify-content-even">
                                            <FormGroup check>
                                                <Label>
                                                    <Input
                                                        name="questionType"
                                                        type="radio"
                                                        value="academyQuiz"
                                                        onChange={onChangeQuestionType}
                                                    />
                                                    {' '}
                                                    For Academy Quiz
                                                </Label>
                                            </FormGroup>
                                            <FormGroup style={{ marginLeft: 20 }} check>
                                                <Label>
                                                    <Input
                                                        name="questionType"
                                                        type="radio"
                                                        value="dailyQuiz"
                                                        onChange={onChangeQuestionType}
                                                    />
                                                    {' '}
                                                    For Daily Quiz
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </Col>
                            }
                            {(addMode && forDailyQuiz) &&
                                <Col md="12">
                                    {(!masterSetList?.loading && masterSetList?.data) &&
                                        <FormGroup>
                                            <Label for="questionSet">
                                                Select a set
                                            </Label>
                                            <Input
                                                id="questionSet"
                                                name="setId"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                invalid={
                                                    validation.touched.setId &&
                                                        validation.errors.setId
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <option value="">Select a set</option>
                                                {masterSetList?.data.sets.map(e => {
                                                    return (
                                                        <option key={e.id} value={e.id}>{e.setName}</option>
                                                    )
                                                })}
                                            </Input>
                                            {validation.touched.setId &&
                                                validation.errors.setId ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.setId}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    }
                                    {(!masterSetList?.loading && masterSetList?.data.length == 0) &&
                                        <label>Set not found</label>
                                    }
                                </Col>
                            }
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationQuestion">Question</Label>
                                    <Input
                                        name="question"
                                        placeholder=""
                                        type="textarea"
                                        className="form-control"
                                        id="validationQuestion"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.question || ""}
                                        invalid={
                                            validation.touched.question &&
                                                validation.errors.question
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.question &&
                                        validation.errors.question ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.question}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationOptionA">Option A</Label>
                                    <Input
                                        name="optionA"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationOptionA"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.optionA || ""}
                                        invalid={
                                            validation.touched.optionA &&
                                                validation.errors.optionA
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.optionA &&
                                        validation.errors.optionA ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.optionA}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationOptionB">Option B</Label>
                                    <Input
                                        name="optionB"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationOptionB"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.optionB || ""}
                                        invalid={
                                            validation.touched.optionB &&
                                                validation.errors.optionB
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.optionB &&
                                        validation.errors.optionB ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.optionB}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationOptionC">Option C</Label>
                                    <Input
                                        name="optionC"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationOptionC"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.optionC || ""}
                                        invalid={
                                            validation.touched.optionC &&
                                                validation.errors.optionC
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.optionC &&
                                        validation.errors.optionC ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.optionC}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationOptionD">Option D</Label>
                                    <Input
                                        name="optionD"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationOptionD"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.optionD || ""}
                                        invalid={
                                            validation.touched.optionD &&
                                                validation.errors.optionD
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.optionD &&
                                        validation.errors.optionD ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.optionD}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCorrectAnswer">Correct Answer</Label>
                                    <div className="input-group has-validation">
                                        <Input
                                            name="correctAnswer"
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            id="validationCorrectAnswer"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.correctAnswer || ""}
                                            invalid={
                                                validation.touched.correctAnswer &&
                                                    validation.errors.correctAnswer
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.correctAnswer &&
                                            validation.errors.correctAnswer ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.correctAnswer}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationExplanation">Explanation</Label>
                                    <div className="input-group has-validation">
                                        <Input
                                            name="explanation"
                                            placeholder=""
                                            type="textarea"
                                            className="form-control"
                                            id="validationExplanation"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.explanation || ""}
                                            invalid={
                                                validation.touched.explanation &&
                                                    validation.errors.explanation
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.explanation &&
                                            validation.errors.explanation ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.explanation}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationTags">Tags</Label>
                                    <div className="input-group has-validation">
                                        <Input
                                            name="tags"
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            id="validationTags"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.tags || ""}
                                            invalid={
                                                validation.touched.tags &&
                                                    validation.errors.tags
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.tags &&
                                            validation.errors.tags ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.tags}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md="12">
                                <div className="d-flex justify-content-end">
                                    <Button
                                        type="submit"
                                        color="dark"
                                        disabled={questionListState.loading}
                                    >
                                        {
                                            questionListState.loading && <Spinner size="sm">
                                                Loading...
                                            </Spinner>
                                        }
                                        <span>
                                            {' '} {addMode ? "Create" : "Update"}
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Questions;
