import {
  INIT_QUIZ_LIST, QUIZ_LIST_SUCCESS, API_ERROR,
  INIT_QUESTION_LIST, QUESTION_LIST_SUCCESS, QUESTION_LIST_FAILED, INIT_QUIZ_CREATE, QUIZ_CREATE_SUCCESS, QUIZ_CREATE_FAILED, INIT_QUIZ_UPDATE, QUIZ_UPDATE_SUCCESS, QUIZ_UPDATE_FAILED, INIT_QUIZ_VIEW, QUIZ_VIEW_SUCCESS, QUIZ_VIEW_FAILED,
  INIT_DAILY_QUIZ_LIST,
  DAILY_QUIZ_SUCCESS,
  DAILY_QUIZ_FAILED,
  INIT_DAILY_QUIZ_CREATE,
  DAILY_QUIZ_CREATE_SUCCESS,
  DAILY_QUIZ_CREATE_FAILED
} from "./actionTypes"

const initialState = {
  quizList: { error: null, loading: false, data: [] },
  questionList: { error: null, loading: false, data: [] },
  quizCreate: { error: null, loading: false, data: [] },
  quizUpdate: { error: null, loading: false, data: [] },
  quizView: { error: null, loading: false, data: [] },
  dailyQuizList: { error: null, loading: false, data: [] },
  dailyQuizCreate: { error: null, loading: false, data: [] }
}

export default function quizReducer(state = initialState, action) {
  switch (action.type) {
    //Quiz List Case
    case INIT_QUIZ_LIST:
      return {
        ...state,
        quizList: { error: null, loading: true, data: [] },
      }
    case QUIZ_LIST_SUCCESS:
      return {
        ...state,
        quizList: {
          ...state.quizList,
          loading: false,
          data: action.payload
        }
      }
    case API_ERROR:
      return {
        ...state,
        quizList: {
          ...state.quizList,
          error: action.payload,
          loading: false
        }
      }
    // Quiz Create Case
    case INIT_QUIZ_CREATE:
      return {
        ...state,
        quizCreate: { error: null, loading: true, data: [] },
      }
    case QUIZ_CREATE_SUCCESS:
      return {
        ...state,
        quizCreate: {
          ...state.quizCreate,
          loading: false,
          data: action.payload
        }
      }
    case QUIZ_CREATE_FAILED:
      return {
        ...state,
        quizCreate: {
          ...state.quizCreate,
          error: action.payload,
          loading: false
        }
      }
    //Quiz Update Case
    case INIT_QUIZ_UPDATE:
      return {
        ...state,
        quizUpdate: { error: null, loading: true, data: [] },
      }
    case QUIZ_UPDATE_SUCCESS:
      return {
        ...state,
        quizUpdate: {
          ...state.quizUpdate,
          loading: false,
          data: action.payload
        }
      }
    case QUIZ_UPDATE_FAILED:
      return {
        ...state,
        quizUpdate: {
          ...state.quizUpdate,
          error: action.payload,
          loading: false
        }
      }
    //Quiz View Case
    case INIT_QUIZ_VIEW:
      return {
        ...state,
        quizView: { error: null, loading: true, data: [] },
      }
    case QUIZ_VIEW_SUCCESS:
      return {
        ...state,
        quizView: {
          ...state.quizView,
          loading: false,
          data: action.payload
        }
      }
    case QUIZ_VIEW_FAILED:
      return {
        ...state,
        quizView: {
          ...state.quizView,
          error: action.payload,
          loading: false
        }
      }
    // Quiz List Question
    case INIT_QUESTION_LIST:
      return {
        ...state,
        questionList: { loading: true }
      }
    case QUESTION_LIST_SUCCESS:
      return {
        ...state,
        questionList: {
          ...state.questionList,
          loading: false,
          data: action.payload
        }
      }
    case QUESTION_LIST_FAILED:
      return {
        ...state,
        questionList: {
          ...state.questionList,
          error: action.payload,
          loading: false
        }
      }
      // Quiz List Question
    case INIT_DAILY_QUIZ_LIST:
      return {
        ...state,
        dailyQuizList: { loading: true, error: null, data: [] }
      }
    case DAILY_QUIZ_SUCCESS:
      return {
        ...state,
        dailyQuizList: {
          ...state.dailyQuizList,
          loading: false,
          data: action.payload
        }
      }
    case DAILY_QUIZ_FAILED:
      return {
        ...state,
        dailyQuizList: {
          ...state.dailyQuizList,
          error: action.payload,
          loading: false
        }
      }
       // Daily Quiz Create
    case INIT_DAILY_QUIZ_CREATE:
      return {
        ...state,
        dailyQuizCreate: { loading: true, error: null, data: [] }
      }
    case DAILY_QUIZ_CREATE_SUCCESS:
      return {
        ...state,
        dailyQuizCreate: {
          ...state.dailyQuizCreate,
          loading: false,
          data: action.payload
        }
      }
    case DAILY_QUIZ_CREATE_FAILED:
      return {
        ...state,
        dailyQuizCreate: {
          ...state.dailyQuizCreate,
          error: action.payload,
          loading: false
        }
      }
    default:
      return { ...state }
  }
}