import {
  QUESTION_SET_LIST_SUCCESS,
  QUESTION_SET_LIST_FAILED,
  INIT_QUESTION_SET_LISt,
  INIT_QUESTION_SET_LIST_ON_MASTER,
  MASTER_QUESTION_SET_LIST_SUCCESS,
  MASTER_QUESTION_SET_LIST_FAILED,
  INIT_QUESTION_SET_ADD,
  MASTER_QUESTION_SET_ADD_SUCCESS,
  MASTER_QUESTION_SET_ADD_FAILED,
  INIT_QUESTION_SET_UPDATE,
  MASTER_QUESTION_SET_UPDATE_SUCCESS,
  MASTER_QUESTION_SET_UPDATE_FAILED
} from "./actionTypes"

const initialState = {
  masterSetList: { error: null, loading: false, data: [] },
  masterSetListOnPage: { error: null, loading: false, data: [] },
  masterSetAdd: { error: null, loading: false, data: [] },
  masterSetUpdate: { error: null, loading: false, data: [] }
}

const masterSet = (state = initialState, action) => {
  switch (action.type) {
    case INIT_QUESTION_SET_LISt:
      return {
        ...state,
        masterSetList: { ...state.masterSetList, loading: true, error: null, data: [] }
      };
    case QUESTION_SET_LIST_SUCCESS:
      return {
        ...state,
        masterSetList: {
          ...state.masterSetList,
          loading: false,
          data: action.payload
        }
      };
    case QUESTION_SET_LIST_FAILED:
      return {
        ...state,
        masterSetList: {
          ...state.masterSetList,
          loading: false,
          error: action.payload
        }
      };

    case INIT_QUESTION_SET_LIST_ON_MASTER:
      return {
        ...state,
        masterSetListOnPage: {
          ...state.masterSetListOnPage,
          loading: true,
          error: null,
          data: []
        }
      };
    case MASTER_QUESTION_SET_LIST_SUCCESS:
      return {
        ...state,
        masterSetListOnPage: {
          ...state.masterSetListOnPage,
          loading: false,
          error: null,
          data: action.payload
        }
      };
    case MASTER_QUESTION_SET_LIST_FAILED:
      return {
        ...state,
        masterSetListOnPage: {
          ...state.masterSetListOnPage,
          error: action.payload,
          loading: false,
          data: []
        }
      };
    case INIT_QUESTION_SET_ADD:
      return {
        ...state,
        masterSetAdd: {
          ...state.masterSetAdd,
          loading: true,
          error: false,
          data: []
        }
      };
    case MASTER_QUESTION_SET_ADD_SUCCESS:
      return {
        ...state,
        masterSetAdd: {
          ...state.masterSetAdd,
          loading: false,
          error: null,
          data: action.payload
        }
      };
    case MASTER_QUESTION_SET_ADD_FAILED:
      return {
        ...state,
        masterSetAdd: {
          ...state.masterSetAdd,
          error: action.payload,
          loading: false,
          data: []
        }
      }
      case INIT_QUESTION_SET_UPDATE:
      return {
        ...state,
        masterSetUpdate: {
          ...state.masterSetUpdate,
          loading: true,
          error: false,
          data: []
        }
      };
    case MASTER_QUESTION_SET_UPDATE_SUCCESS:
      return {
        ...state,
        masterSetUpdate: {
          ...state.masterSetUpdate,
          loading: false,
          error: null,
          data: action.payload
        }
      };
    case MASTER_QUESTION_SET_UPDATE_FAILED:
      return {
        ...state,
        masterSetUpdate: {
          ...state.masterSetUpdate,
          error: action.payload,
          loading: false,
          data: []
        }
      }
    default:
      return state
  }
}

export default masterSet;
