import { call, put, takeEvery } from "redux-saga/effects";

// Enrolment Redux States
import { INIT_DAILY_QUIZ_CREATE, INIT_DAILY_QUIZ_LIST, INIT_QUESTION_LIST, INIT_QUIZ_CREATE, INIT_QUIZ_LIST, INIT_QUIZ_UPDATE, INIT_QUIZ_VIEW } from "./actionTypes";
import { dailyQuizCreateFailed, dailyQuizCreateSuccess, dailyQuizListFailed, dailyQuizListSuccess, questionListFailed, questionListSuccess, quizCreateFailed, quizCreateSuccess, quizListFailed, quizListSuccess, quizUpdateFailed, quizUpdateSuccess, quizViewFailed, quizViewSuccess } from "./actions";
import { createQuiz, getQuestionList, getQuizList, updateQuiz, viewQuiz, dailyQuizList, dailyQuizCreate } from "helpers/backend_helper";

//Include Both Helper File with needed methods
function* quizList() {
  try {
    const response = yield call(getQuizList);

    if (response && response.statusCode == 200) {
      yield put(quizListSuccess(response));
    } else {
      yield put(quizListFailed(response.message));
    }
  } catch (error) {
    yield put(quizListFailed(error));
  }
}

function* quizView({payload}) {
  try {
    const response = yield call(viewQuiz, {...payload});

    if (response && response.statusCode == 200) {
      yield put(quizViewSuccess(response));
    } else {
      yield put(quizViewFailed(response.message));
    }
  } catch (error) {
    yield put(quizViewFailed(error));
  }
}

function* quizCreate({ payload }) {
  try {
    const response = yield call(createQuiz, { data: { quizTitle: payload.quizTitle, description: payload.description, targetCourse: payload.targetCourse }, questions: payload.questions });

    if (response && response.statusCode == 200) {
      yield put(quizCreateSuccess(response));
    } else {
      yield put(quizCreateFailed(response.message));
    }
  } catch (error) {
    yield put(quizCreateFailed(error));
  }
}

function* quizUpdate({ payload }) {
  try {
    let updateObj = {
      "data": {}
    };
    if (payload) {
      if (payload.quizId) updateObj['data']['quizId'] = payload.quizId;
      if (payload.quizTitle) updateObj['data']['quizTitle'] = payload.quizTitle;
      if (payload.description) updateObj['data']['description'] = payload.description;
      if (payload.targetCourse) updateObj['data']['targetCourse'] = payload.targetCourse;
      if (payload.questions) {
        updateObj["questions"] = [];
        updateObj['questions'] = payload.questions;
      }
      if (payload.isPublished == 0 || payload.isPublished == 1) updateObj['data']['isPublished'] = Boolean(payload.isPublished);
      if (payload.status == 0 || payload.status == 1) updateObj['data']['status'] = Boolean(payload.status);
    }

    const response = yield call(updateQuiz, updateObj);

    if (response && response.statusCode == 200) {
      yield put(quizUpdateSuccess(response));
    } else {
      yield put(quizUpdateFailed(response.message));
    }
  } catch (error) {
    yield put(quizUpdateFailed(error));
  }
}

function* questionList(data) {
  try {
    const response = yield call(getQuestionList, {...data.payload});

    if (response && response.statusCode == 200) {
      yield put(questionListSuccess(response));
    } else {
      yield put(questionListFailed(response.message));
    }
  } catch (error) {
    yield put(questionListFailed(error));
  }
}

function* fetchDailyQuizList() {
  try {
    const response = yield call(dailyQuizList);

    if (response && response.statusCode == 200) {
      yield put(dailyQuizListSuccess(response));
    } else {
      yield put(dailyQuizListFailed(response.message));
    }
  } catch (error) {
    yield put(dailyQuizListFailed(error));
  }
}

function* createDailyQuiz({payload}) {
  try {
    const response = yield call(dailyQuizCreate, {...payload});

    if (response && response.statusCode == 200) {
      yield put(dailyQuizCreateSuccess(response));
    } else {
      yield put(dailyQuizCreateFailed(response.message));
    }
  } catch (error) {
    yield put(dailyQuizCreateFailed(error));
  }
}

function* quizSaga() {
  yield takeEvery(INIT_QUIZ_LIST, quizList);
  yield takeEvery(INIT_QUESTION_LIST, questionList);
  yield takeEvery(INIT_QUIZ_CREATE, quizCreate);
  yield takeEvery(INIT_QUIZ_UPDATE, quizUpdate);
  yield takeEvery(INIT_QUIZ_VIEW, quizView);
  yield takeEvery(INIT_DAILY_QUIZ_LIST, fetchDailyQuizList);
  yield takeEvery(INIT_DAILY_QUIZ_CREATE, createDailyQuiz);
}

export default quizSaga;
