import { call, put, takeEvery } from "redux-saga/effects";

// Enrolment Redux States
import { INIT_QUESTION_SET_ADD, INIT_QUESTION_SET_LIST_ON_MASTER, INIT_QUESTION_SET_LISt, INIT_QUESTION_SET_UPDATE } from "./actionTypes";
import { questionSetListSuccessOnMasterPage, questionSetListFailedOnMasterPage, questionSetAddSuccess, questionSetAddFailed, questionSetUpdateSuccess, questionSetUpdareFailed, questionSetListSuccess, questionSetListFailed } from "./actions";

//Include Both Helper File with needed methods
import {
  addNewMasterSet,
  getMasterSetList,
  updateNewMasterSet
} from "../../helpers/backend_helper";

function* masterSetListOnDropdown({payload}) {
  try {
    const response = yield call(getMasterSetList, {
      ...payload
    });
    if (response && response.statusCode == 200) {
      yield put(questionSetListSuccess(response));
    } else {
      yield put(questionSetListFailed(response.message));
    }
  } catch (error) {
    yield put(questionSetListFailed(error));
  }
}

function* masterSetListOnPage() {
  try {
    const response = yield call(getMasterSetList);
    if (response && response.statusCode == 200) {
      yield put(questionSetListSuccessOnMasterPage(response));
    } else {
      yield put(questionSetListFailedOnMasterPage(response.message));
    }
  } catch (error) {
    yield put(questionSetListFailedOnMasterPage(error));
  }
}

function* masterSetAddOnPage({ payload }) {
  try {
    const response = yield call(addNewMasterSet, {
      data: {...payload}
    });
    if (response && response.statusCode == 200) {
      yield put(questionSetAddSuccess(response));
    } else {
      yield put(questionSetAddFailed(response.message));
    }
  } catch (error) {
    yield put(questionSetAddFailed(error));
  }
}

function* masterSetUpdateOnPage({ payload }) {
  try {
    const response = yield call(updateNewMasterSet, {
      setId: payload.setId,
      data: {
        setName: payload.setName,
        setDescription: payload.setDescription
      }
    });
    if (response && response.statusCode == 200) {
      yield put(questionSetUpdateSuccess(response));
    } else {
      yield put(questionSetUpdareFailed(response.message));
    }
  } catch (error) {
    yield put(questionSetUpdareFailed(error));
  }
}

function* masterSetOnPageSaga() {
  yield takeEvery(INIT_QUESTION_SET_LISt, masterSetListOnDropdown);
  yield takeEvery(INIT_QUESTION_SET_LIST_ON_MASTER, masterSetListOnPage);
  yield takeEvery(INIT_QUESTION_SET_ADD, masterSetAddOnPage);
  yield takeEvery(INIT_QUESTION_SET_UPDATE, masterSetUpdateOnPage);
}

export default masterSetOnPageSaga;
