// Quiz Listing
export const INIT_QUIZ_LIST = "INIT_QUIZ_LIST";
export const QUIZ_LIST_SUCCESS = "QUIZ_LIST_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

//New Quiz Create 
export const INIT_QUIZ_CREATE = "INIT_QUIZ_CREATE";
export const QUIZ_CREATE_SUCCESS = "QUIZ_CREATE_SUCCESS";
export const QUIZ_CREATE_FAILED = "QUIZ_CREATE_FAILED";

//Quiz Update 
export const INIT_QUIZ_UPDATE = "INIT_QUIZ_UPDATE";
export const QUIZ_UPDATE_SUCCESS = "QUIZ_UPDATE_SUCCESS";
export const QUIZ_UPDATE_FAILED = "QUIZ_UPDATE_FAILED";

//Quiz View 
export const INIT_QUIZ_VIEW = "INIT_QUIZ_VIEW";
export const QUIZ_VIEW_SUCCESS = "QUIZ_VIEW_SUCCESS";
export const QUIZ_VIEW_FAILED = "QUIZ_VIEW_FAILED";

//Question List
export const INIT_QUESTION_LIST = "INIT_QUESTION_LIST_FOR_QUIZ";
export const QUESTION_LIST_SUCCESS = "QUIZ_QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_FAILED = "QUIZ_QUESTION_LIST_FAILED";

//Daily Quiz Lsit
export const INIT_DAILY_QUIZ_LIST = "INI_DAILY_QUIZ";
export const DAILY_QUIZ_SUCCESS = "DAILY_QUIZ_SUCCESS";
export const DAILY_QUIZ_FAILED = "DAILY_QUIZ_FAILED";

//New Daily Quiz Create 
export const INIT_DAILY_QUIZ_CREATE = "INIT_DAILY_QUIZ_CREATE";
export const DAILY_QUIZ_CREATE_SUCCESS = "DAILY_QUIZ_CREATE_SUCCESS";
export const DAILY_QUIZ_CREATE_FAILED = "DAILY_QUIZ_CREATE_FAILED";

//New Daily Quiz Update 
export const INIT_DAILY_QUIZ_UPDATE = "INIT_DAILY_QUIZ_UPDATE";
export const DAILY_QUIZ_UPDATE_SUCCESS = "DAILY_QUIZ_UPDATE_SUCCESS";
export const DAILY_QUIZ_UPDATE_FAILED = "DAILY_QUIZ_UPDATE_FAILED";