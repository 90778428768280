import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import enrolmentSaga from "./enrolment/saga"
import quizSaga from "./quiz/saga"
import questionSaga from "./questions/saga"
import curriculumSaga from "./curriculum/saga"
import registrationSaga from "./registration/saga"
import masterSetSaga from "./masterSets/saga"


export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(enrolmentSaga),
    fork(quizSaga),
    fork(questionSaga),
    fork(curriculumSaga),
    fork(registrationSaga),
    fork(masterSetSaga),
    LayoutSaga(),
  ])
}
