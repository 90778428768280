import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { initEnrolmentList } from "store/actions";

const DatatableTables = () => {
    const dispatch = useDispatch();
    const { Enrolment } = useSelector(state => {
        return state;
    });
    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    });

    useEffect(() => {
        dispatch(initEnrolmentList())
    }, []);

    useEffect(() => {
        if (Enrolment.data.enrollments?.length) {
            setTableData({
                columns: [{
                    label: "Candidate Name",
                    field: "candidateName",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Father Name",
                    field: "fatherName",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Contact No",
                    field: "contactNo",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Course",
                    field: "course",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Duration",
                    field: "duration",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Date of Birth",
                    field: "dateOfBirth",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Enrollment Date",
                    field: "createdAt",
                    sort: "asc",
                    width: 150,
                }],
                rows: Enrolment.data.enrollments
            })
        }
    }, [Enrolment.data.enrollments]);


    document.title = "Enrolment | Admin | Kanha Computer Academy";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Enrolment List" />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {Enrolment.loading &&
                                        <div className="text-center">
                                            <div className="p-5 d-flex justify-content-center">
                                                <Spinner className="ms-2" color="dark" />
                                            </div>
                                            <div>Please wait while loading data...</div>
                                        </div>
                                    }
                                    {!Enrolment.loading &&
                                        <MDBDataTable noBottomColumns responsive bordered data={tableData} />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DatatableTables;
