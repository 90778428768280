import {
  INIT_ENROLMENT_LIST,
  ENROLMENT_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  data: []
}

const enrolment = (state = initialState, action) => {
  switch (action.type) {
    case INIT_ENROLMENT_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case ENROLMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default enrolment;
