import axios from "axios"

//pass new generated access token here
let localToken = localStorage.getItem("admin-kca") || null;
//apply base url for axios
const {NODE_ENV, REACT_APP_BASE_PATH, REACT_APP_BASE_PATH_PROD} = process.env;
const API_URL = NODE_ENV && NODE_ENV === "development" ? REACT_APP_BASE_PATH : REACT_APP_BASE_PATH_PROD;

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if(error && error.response && error.response.status && error.response.status === 401){ Promise.reject(error); logoutOn401() }
    else Promise.reject(error)
  }
)

export function logoutOn401(){
  localStorage.removeItem("admin-kca")
  window.location.href = "/login"
}

axiosApi.interceptors.request.use(function(config){
  const token = global.token || localToken;
  if(token) config.headers.Authorization =  "Bearer " + token;
     
  return config;
});

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data = {}, config = {}) { 
  return axiosApi.post(url, { ...data }, { ...config }).then(response => response?.data)
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then(response => response.data)
}
