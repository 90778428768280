import { post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("admin-kca")
  if (user) return JSON.parse(user)
  return null;
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data)

// Enrolment List Method
const getEnrolmentList = data => post(url.ENROLMENT_LIST, data)

// Quiz List Method
const getQuizList = data => post(url.QUIZ_LIST);

// Question List Method
const getQuestionList = data => post(url.QUESTION_LIST, data);
const getQuestionMasterList = data => post(url.QUESTION_LIST);

// Question Add Method
const addNewQuestion = data => post(url.QUESTION_ADD, data);

// Question Update Method
const updateQuestion = data => put(url.QUESTION_UPDATE, data);

// Quiz Create Method
const createQuiz = data => post(url.QUIZ_CREATE, data);

// Quiz Update Method
const updateQuiz = data => put(url.QUIZ_UPDATE, data);

// Quiz View Method
const viewQuiz = data => post(url.QUIZ_VIEW, data);

// Curriculum Create Method
const createCurriculum = data => post(url.CREATE_CURRICULUM, data);

// View Curriculum Method
const viewCurriculum = data => post(url.VIEW_CURRICULUM, data);

// List Curriculum Method
const listCurriculum = data => post(url.CURRICULUM_LIST, data);

// Edit Curriculum Method
const editCurriculum = data => put(url.EDIT_CURRICULUM, data);

/** Registration Related Backend Call */
// Create Method
const newRegistration = data => post(url.CREATE_CURRICULUM, data);
// List Registration Method
const listRegistration = data => post(url.REGISTRATION_LIST, data);

// Master Set List
const getMasterSetList = data => post(url.MASTER_SET_LIST, data);

// Master Set Add
const addNewMasterSet = data => post(url.MASTER_SET_ADD, data);

// Master Set Update
const updateNewMasterSet = data => put(url.MASTER_SET_UPDATE, data);

// Daily Quiz List
const dailyQuizList = data => post(url.DAILY_QUIZ_LIST, data);

// Daily Quiz Create
const dailyQuizCreate = data => post(url.DAILY_QUIZ_CREATE, data);

// Daily Quiz Update
const dailyQuizUpdate = data => post(url.DAILY_QUIZ_UPDATE, data);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  getEnrolmentList,
  getQuizList,
  getQuestionList,
  getQuestionMasterList,
  addNewQuestion,
  updateQuestion,
  createQuiz,
  updateQuiz,
  viewQuiz,
  createCurriculum,
  viewCurriculum,
  listCurriculum,
  editCurriculum,
  newRegistration,
  listRegistration,
  getMasterSetList,
  addNewMasterSet,
  updateNewMasterSet,
  dailyQuizCreate,
  dailyQuizUpdate,
  dailyQuizList
}
