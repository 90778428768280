import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { initRegistrationList } from "store/actions";

const Registration = () => {
    const dispatch = useDispatch();
    const {Registration} = useSelector(state => {
        return state;
    });
    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    });
    
    useEffect(() => {
        dispatch(initRegistrationList())
    }, []);

    useEffect(() => {
        if (Registration?.registrationList?.data?.registrationList?.length) {
            setTableData({
                columns: [{
                    label: "Candidate Name",
                    field: "studentName",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Registration No",
                    field: "registrationNo",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Registration Date",
                    field: "registrationDate",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Registration By",
                    field: "registrationBy",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Enrollment Status",
                    field: "enrollmentStatus",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Enrollment Source",
                    field: "enrollmentSource",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Course",
                    field: "course",
                    sort: "asc",
                    width: 150,
                },{
                    label: "Category",
                    field: "category",
                    sort: "asc",
                    width: 150,
                },{
                    label: "Remark",
                    field: "remark",
                    sort: "asc",
                    width: 150,
                }],
                rows: Registration.registrationList.data.registrationList
            })
        }
    }, [Registration.registrationList.data]);


    document.title = "Registration | Admin | Kanha Computer Academy";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Registration List" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {Registration.registrationList.loading &&
                                        <div className="text-center">
                                            <div className="p-5 d-flex justify-content-center">
                                                <Spinner className="ms-2" color="dark" />
                                            </div>
                                            <div>Please wait while loading data...</div>
                                        </div>
                                    }
                                    {!Registration.registrationList.loading &&
                                        <MDBDataTable noBottomColumns responsive bordered data={tableData} />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Registration;
