import { listRegistration } from "helpers/backend_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { registrationListFailed, registrationListSuccess } from "./actions";
import { INIT_REGISTRATION_LIST } from "./actionTypes";

//Include Both Helper File with needed methods
function* registrationList() {
  try {
    const response = yield call(listRegistration);

    if (response && response.statusCode == 200) {
      yield put(registrationListSuccess(response));
    } else {
      yield put(registrationListFailed(response.message));
    }
  } catch (error) {
    yield put(registrationListFailed(error));
  }
}

function* registrationSaga() {
  yield takeEvery(INIT_REGISTRATION_LIST, registrationList);
}

export default registrationSaga;
