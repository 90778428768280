import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Badge, Button, FormGroup, Label, Input, FormFeedback, Form } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { initQuestionSetAdd, initQuestionSetListOnMasterPage, initQuestionSetUpdate } from "store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";

const MasterSetPage = () => {
    const dispatch = useDispatch();

    const {masterSetListOnPage, masterSetAdd, masterSetUpdate} = useSelector(state => {
        return state.MasterSet;
    });

    const [setId, setSetId] = useState(null);

    useEffect(() => {
        dispatch(initQuestionSetListOnMasterPage())
    }, []);

    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    });

    useEffect(() => {

        if (masterSetListOnPage.data.sets?.length) {
            const modifiedSetData = masterSetListOnPage.data.sets?.map(e => {
                e["status"] = e["status"] === true ? <Badge onClick={() => updateSet(e["id"], "status", 0)} className="bg-success" style={{ fontSize: 13 }}>
                    Active
                </Badge> : <Badge onClick={() => updateSet(e["id"], "status", 1)} className="bg-danger" style={{ fontSize: 13 }}>
                    Inactive
                </Badge>;
                e["action"] = <Button color="link" onClick={() => onClickEditButton(e)} className="btn btn-link waves-effect btn-sm">Edit</Button>
                return e;
            });
            setTableData({
                columns: [{
                    label: "Set Name",
                    field: "setName",
                    sort: "asc",
                    width: 250,
                },
                {
                    label: "Set Description",
                    field: "setDescription",
                    sort: "asc",
                    width: 780,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Created At",
                    field: "createdAt",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Updated At",
                    field: "updatedAt",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Actions",
                    field: "action",
                    sort: "asc",
                    width: 150,
                }],
                rows: modifiedSetData
            })
        }
    }, [masterSetListOnPage.data.sets]);
    
    useEffect(()=>{
        if(masterSetAdd?.error && masterSetAdd?.error.indexOf("already exists") > -1){
            alert("Set name already exists")
        }
        if (masterSetAdd.data.message == "New set added successfully") {
            validation.setValues({setName: "", setDescription: ""})
            dispatch(initQuestionSetListOnMasterPage())
        }
    }, [masterSetAdd])

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            setName: '',
            setDescription: ''
        },
        validationSchema: Yup.object({
            setName: setId == null ? Yup.string().required("Please enter set name") : Yup.string().optional(),
            setDescription: setId == null ? Yup.string().required("Please enter set description") : Yup.string().optional()
        }),
        onSubmit: (values) => {
            if(setId != null){
                values.setId = setId;
                dispatch(initQuestionSetUpdate({ ...values }))
                validation.setValues({setName: "", setDescription: ""})
                setSetId(null)
            }else dispatch(initQuestionSetAdd({ ...values }))
        }
    });

    const updateSet = (id, type, status) => {
        
    }

    const onClickEditButton = (dataObj) => {
        validation.setValues({
            setName: dataObj["setName"],
            setDescription: dataObj["setDescription"]
        })
        setSetId(dataObj["id"])
    }

    useEffect(()=>{
        if(!masterSetUpdate.loading && masterSetUpdate.error){
            alert(masterSetUpdate.error)
        }
        else if(!masterSetUpdate.loading && masterSetUpdate.data.message){
            alert(masterSetUpdate.data.message)
            dispatch(initQuestionSetListOnMasterPage())
        }
    }, [masterSetUpdate?.data])

    document.title = "Master Set | Admin | Kanha Computer Academy";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between">
                        <Breadcrumbs title="Master Set" />
                    </div>
                    <Row>
                        <Col md={12}>
                            <Form
                                className="row mt-2 g-3 needs-validation"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col md="5">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationSetName">Set Name</Label>
                                            <Input
                                                name="setName"
                                                placeholder=""
                                                type="text"
                                                className="form-control"
                                                id="validationSetName"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.setName || ""}
                                                invalid={
                                                    validation.touched.setName &&
                                                        validation.errors.setName
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.setName &&
                                                validation.errors.setName ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.setName}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="5">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationsetDescription">Set Description</Label>
                                            <Input
                                                name="setDescription"
                                                placeholder=""
                                                type="text"
                                                className="form-control"
                                                id="validationsetDescription"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.setDescription || ""}
                                                invalid={
                                                    validation.touched.setDescription &&
                                                        validation.errors.setDescription
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.setDescription &&
                                                validation.errors.setDescription ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.setDescription}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <div className="d-flex justify-content-end align-items-center h-100">
                                            <Button
                                                type="submit"
                                                color="dark"
                                                disabled={masterSetListOnPage.loading}
                                            >
                                                {
                                                    masterSetListOnPage.loading && <Spinner size="sm">
                                                        Loading...
                                                    </Spinner>
                                                }
                                                <span>
                                                    {' '}{setId == null ? "Create" : "Update"}
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {masterSetListOnPage.loading &&
                                        <div className="text-center">
                                            <div className="p-5 d-flex justify-content-center">
                                                <Spinner className="ms-2" color="dark" />
                                            </div>
                                            <div>Please wait while loading data...</div>
                                        </div>
                                    }
                                    {!masterSetListOnPage.loading &&
                                        <MDBDataTable noBottomColumns responsive bordered data={tableData} />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MasterSetPage;
