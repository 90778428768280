import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

// Enrolment
import Enrolment from "./enrolment/reducer";

// Quiz
import Quiz from "./quiz/reducer";

// Question
import Question from "./questions/reducer";

// Curriculum
import Curriculum from "./curriculum/reducer";

// Registration
import Registration from "./registration/reducer";

// Master Set
import MasterSet from "./masterSets/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Enrolment,
  Quiz,
  Question,
  Curriculum,
  Registration,
  MasterSet
})

export default rootReducer
