import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Badge, Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, FormFeedback, Form } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { initCourseCurriculumCreate, initMasterCourseCurriculumList } from "store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import QuillToolbar, { formats, modules } from "./EditorToolbar";
import { Link } from "react-router-dom";

const CurriculumPage = () => {
    const dispatch = useDispatch();
    const { Curriculum } = useSelector(state => {
        return state;
    });

    const [quillValue, setQuillValue] = useState({value: null});

    useEffect(() => {
        dispatch(initMasterCourseCurriculumList())
    }, []);

    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    });

    const updateCurriculum = () => {
        
    }

    useEffect(() => {
        if (Curriculum?.data?.curriculum?.length) {
            const modifiedCurriculumData = Curriculum?.data?.curriculum?.map(e => {
                e["status"] = e["status"] === true ? <Badge onClick={() => updateCurriculum(e["id"], "status", 0)} className="bg-success" style={{ fontSize: 13 }}>
                    Active
                </Badge> : <Badge onClick={() => updateCurriculum(e["id"], "status", 1)} className="bg-danger" style={{ fontSize: 13 }}>
                    Inactive
                </Badge>;
                
                e["action"] = <><Link to={`./view/${e.id}`} className="btn btn-link waves-effect btn-sm">View/Edit</Link></>
                e["createdAt"] = new Date(e.createdAt).toDateString();
                e["updatedAt"] = new Date(e.updatedAt).toDateString();
                return e;
            });
            setTableData({
                columns: [{
                    label: "Course Type",
                    field: "courseType",
                    sort: "asc",
                    width: 250,
                },
                {
                    label: "University",
                    field: "university",
                    sort: "asc",
                    width: 780,
                },
                {
                    label: "Trade",
                    field: "trade",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Eligibility",
                    field: "eligibility",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Duration",
                    field: "duration",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Created At",
                    field: "createdAt",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Updated At",
                    field: "updatedAt",
                    sort: "asc",
                    width: 150
                },
                {
                    label: "Actions",
                    field: "action",
                    sort: "asc",
                    width: 150,
                }],
                rows: modifiedCurriculumData
            })
        }
    }, [Curriculum?.data?.curriculum]);

    const [modal_large, setmodal_large] = useState(false);
    const [addMode, setAddMode] = useState(true);

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const newCurriculum = () => {
        setmodal_large(!modal_large);
        removeBodyCss();
        setAddMode(true);
    }

    const handleChangeQuill = value => {
        setQuillValue({ value });
        validation.values.longdescription = value
      };

    // Form validation 
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            courseType: '',
            university: '',
            trade: '',
            name: '',
            eligibility: '',
            duration: '',
            title: '',
            subtitle: '',
            longdescription: '',
            tags: ''
        },
        validationSchema: Yup.object({
            courseType: Yup.string().required("Please enter courseType"),
            university: Yup.string().required("Please enter university"),
            trade: Yup.string().required("Please enter trade"),
            name: Yup.string().required("Please enter name"),
            eligibility: Yup.string().required("Please enter eligibility"),
            duration: Yup.string().required("Please enter duration"),
            title: Yup.string().required("Please enter title"),
            subtitle: Yup.string().required("Please enter subtitle"),
            longdescription: Yup.string().required("Please enter description"),
            tags: Yup.string().required("Please enter tags")
        }),
        onSubmit: (values) => {
            dispatch(initCourseCurriculumCreate({ ...values }))
        }
    });

    document.title = "Course Curriculum | Admin | Kanha Computer Academy";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between">
                        <Breadcrumbs title="Course Curriculum" />
                        <Button className="waves-effect waves-light btn btn-dark" onClick={newCurriculum}>Add New Curriculum</Button>
                    </div>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {Curriculum.loading &&
                                        <div className="text-center">
                                            <div className="p-5 d-flex justify-content-center">
                                                <Spinner className="ms-2" color="dark" />
                                            </div>
                                            <div>Please wait while loading data...</div>
                                        </div>
                                    }
                                    {!Curriculum.loading &&
                                        <MDBDataTable noBottomColumns responsive bordered data={tableData} />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal isOpen={modal_large} toggle={newCurriculum} size="lg" scrollable>
                <ModalHeader className="mt-0" toggle={newCurriculum}>{addMode ? "Add new curriculum" : "Edit curriculum"}</ModalHeader>
                <ModalBody>
                    <Form
                        className="row mt-2 g-3 needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col md="6" xs="12" sm="12" lg="6" xl="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCourseType">Course Type</Label>
                                    <Input
                                        name="courseType"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationCourseType"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.courseType || ""}
                                        invalid={
                                            validation.touched.courseType &&
                                                validation.errors.courseType
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.courseType &&
                                        validation.errors.courseType ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.courseType}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6" xs="12" sm="12" lg="6" xl="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationUniversity">University</Label>
                                    <Input
                                        name="university"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationUniversity"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.university || ""}
                                        invalid={
                                            validation.touched.university &&
                                                validation.errors.university
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.university &&
                                        validation.errors.university ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.university}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6" xs="12" sm="12" lg="6" xl="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationTrade">Trade</Label>
                                    <Input
                                        name="trade"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationTrade"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.trade || ""}
                                        invalid={
                                            validation.touched.trade &&
                                                validation.errors.trade
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.trade &&
                                        validation.errors.trade ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.trade}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6" xs="12" sm="12" lg="6" xl="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationName">Name</Label>
                                    <Input
                                        name="name"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name &&
                                                validation.errors.name
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.name &&
                                        validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.name}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6" xs="12" sm="12" lg="6" xl="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationEligibility">Eligibility</Label>
                                    <Input
                                        name="eligibility"
                                        placeholder=""
                                        type="text"
                                        className="form-control"
                                        id="validationEligibility"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.eligibility || ""}
                                        invalid={
                                            validation.touched.eligibility &&
                                                validation.errors.eligibility
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.eligibility &&
                                        validation.errors.eligibility ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.eligibility}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6" xs="12" sm="12" lg="6" xl="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCorrectDuration">Duration</Label>
                                    <div className="input-group has-validation">
                                        <Input
                                            name="duration"
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            id="validationCorrectDuration"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.duration || ""}
                                            invalid={
                                                validation.touched.duration &&
                                                    validation.errors.duration
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.duration &&
                                            validation.errors.duration ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.duration}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationTitle">Title</Label>
                                    <div className="input-group has-validation">
                                        <Input
                                            name="title"
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            id="validationTitle"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.title || ""}
                                            invalid={
                                                validation.touched.title &&
                                                    validation.errors.title
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.title &&
                                            validation.errors.title ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.title}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationSubtitle">Subtitle</Label>
                                    <div className="input-group has-validation">
                                        <Input
                                            name="subtitle"
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            id="validationSubtitle"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.subtitle || ""}
                                            invalid={
                                                validation.touched.subtitle &&
                                                    validation.errors.subtitle
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.subtitle &&
                                            validation.errors.subtitle ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.subtitle}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </FormGroup>
                            </Col>

                            <Col md="12">
                                <QuillToolbar />
                                <ReactQuill
                                    theme="snow"
                                    value={quillValue.value}
                                    onChange={handleChangeQuill}
                                    placeholder={"Write something awesome..."}
                                    modules={modules}
                                    formats={formats}
                                />
                            </Col>

                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationTags">Tags</Label>
                                    <div className="input-group has-validation">
                                        <Input
                                            name="tags"
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            id="validationTags"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.tags || ""}
                                            invalid={
                                                validation.touched.tags &&
                                                    validation.errors.tags
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.tags &&
                                            validation.errors.tags ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.tags}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col md="12">
                                <div className="d-flex justify-content-end">
                                    <Button
                                        type="submit"
                                        color="dark"
                                        disabled={Curriculum.loading}
                                    >
                                        {
                                            Curriculum.loading && <Spinner size="sm">
                                                Loading...
                                            </Spinner> 
                                        }
                                        <span>
                                            {' '}Create
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default CurriculumPage;
