import {
  INIT_ENROLMENT_LIST,
  API_ERROR,
  ENROLMENT_SUCCESS,
} from "./actionTypes"

export const initEnrolmentList = (data) => {
  return {
    type: INIT_ENROLMENT_LIST,
    payload: data
  }
}

export const enrolmentListSuccess = (data) => {
  return {
    type: ENROLMENT_SUCCESS,
    payload: data,
  }
}

export const enrolmentListFailed = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}