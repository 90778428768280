import {
  INIT_REGISTRATION_LIST,
  REGISTRATION_LIST_SUCCESS,
  REGISTRATION_LIST_FAILED
} from "./actionTypes"

// QUIZ LIST ACTIONS
export const initRegistrationList = (data) => {
  return {
    type: INIT_REGISTRATION_LIST,
    payload: data
  }
}

export const registrationListSuccess = (data) => {
  return {
    type: REGISTRATION_LIST_SUCCESS,
    payload: data,
  }
}

export const registrationListFailed = error => {
  return {
    type: REGISTRATION_LIST_FAILED,
    payload: error,
  }
}