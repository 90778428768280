import { call, put, takeEvery } from "redux-saga/effects";

// Enrolment Redux States
import { INIT_CURRICULUM_CREATE, INIT_CURRICULUM_LIST, INIT_CURRICULUM_UPDATE, INIT_CURRICULUM_VIEW } from "./actionTypes";
import { curriculumCreateFailed, curriculumCreateSuccess, curriculumEditFailed, curriculumEditSuccess, curriculumListFailed, curriculumListSuccess, curriculumViewFailed, curriculumViewSuccess } from "./actions";

//Include Both Helper File with needed methods
import {
  createCurriculum,
  viewCurriculum,
  listCurriculum,
  editCurriculum
} from "../../helpers/backend_helper";

function* curriculumList() {
  try {
    const response = yield call(listCurriculum);
    if (response && response.statusCode == 200) {
      yield put(curriculumListSuccess(response));
    } else {
      yield put(curriculumListFailed(response.message));
    }
  } catch (error) {
    yield put(curriculumListFailed(error));
  }
}

function* createCourseCurriculum({ payload }) {
  try {
    const response = yield call(createCurriculum, {
      data: {...payload}
    });
    if (response && response.statusCode == 200) {
      yield put(curriculumCreateSuccess(response));
    } else {
      yield put(curriculumCreateFailed(response.message));
    }
  } catch (error) {
    yield put(curriculumCreateFailed(error));
  }
}

function* viewCourseCurriculum({ payload }) {
  try {
    const response = yield call(viewCurriculum, {
      data: {...payload}
    });
    if (response && response.statusCode == 200) {
      yield put(curriculumViewSuccess(response));
    } else {
      yield put(curriculumViewFailed(response.message));
    }
  } catch (error) {
    yield put(curriculumViewFailed(error));
  }
}

function* editCourseCurriculum({ payload }) {
  try {
    const response = yield call(editCurriculum, {
      data: {...payload}
    });
    if (response && response.statusCode == 200) {
      yield put(curriculumEditSuccess(response));
    } else {
      yield put(curriculumEditFailed(response.message));
    }
  } catch (error) {
    yield put(curriculumEditFailed(error));
  }
}

function* curriculumSaga() {
  yield takeEvery(INIT_CURRICULUM_LIST, curriculumList);
  yield takeEvery(INIT_CURRICULUM_CREATE, createCourseCurriculum);
  yield takeEvery(INIT_CURRICULUM_VIEW, viewCourseCurriculum);
  yield takeEvery(INIT_CURRICULUM_UPDATE, editCourseCurriculum);

}

export default curriculumSaga;
