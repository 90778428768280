import {
  INIT_QUIZ_LIST, API_ERROR, QUIZ_LIST_SUCCESS,
  INIT_QUESTION_LIST, QUESTION_LIST_SUCCESS, QUESTION_LIST_FAILED, INIT_QUIZ_CREATE, QUIZ_CREATE_SUCCESS, QUIZ_CREATE_FAILED, QUIZ_UPDATE_SUCCESS, QUIZ_UPDATE_FAILED, INIT_QUIZ_UPDATE, INIT_QUIZ_VIEW, QUIZ_VIEW_SUCCESS, QUIZ_VIEW_FAILED,
  INIT_DAILY_QUIZ_LIST,
  DAILY_QUIZ_SUCCESS,
  DAILY_QUIZ_FAILED,
  DAILY_QUIZ_CREATE_FAILED,
  DAILY_QUIZ_CREATE_SUCCESS,
  INIT_DAILY_QUIZ_CREATE
} from "./actionTypes"

// QUIZ LIST ACTIONS
export const initQuizList = (data) => {
  return {
    type: INIT_QUIZ_LIST,
    payload: data
  }
}

export const quizListSuccess = (data) => {
  return {
    type: QUIZ_LIST_SUCCESS,
    payload: data,
  }
}

export const quizListFailed = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

// Quiz Create Action
export const initQuizCreate = (data) => {
  return {
    type: INIT_QUIZ_CREATE,
    payload: data
  }
}

export const quizCreateSuccess = (data) => {
  return {
    type: QUIZ_CREATE_SUCCESS,
    payload: data,
  }
}

export const quizCreateFailed = error => {
  return {
    type: QUIZ_CREATE_FAILED,
    payload: error,
  }
}

// Quiz Update Action
export const initQuizUpdate = (data) => {
  return {
    type: INIT_QUIZ_UPDATE,
    payload: data
  }
}

export const quizUpdateSuccess = (data) => {
  return {
    type: QUIZ_UPDATE_SUCCESS,
    payload: data,
  }
}

export const quizUpdateFailed = error => {
  return {
    type: QUIZ_UPDATE_FAILED,
    payload: error,
  }
}

// Quiz View Action
export const initQuizView = (data) => {
  return {
    type: INIT_QUIZ_VIEW,
    payload: data
  }
}

export const quizViewSuccess = (data) => {
  return {
    type: QUIZ_VIEW_SUCCESS,
    payload: data,
  }
}

export const quizViewFailed = error => {
  return {
    type: QUIZ_VIEW_FAILED,
    payload: error,
  }
}

// QUESTION LIST ACTIONS
export const initQuestionList = (data) => {
  return {
    type: INIT_QUESTION_LIST,
    payload: data
  }
}

export const questionListSuccess = (data) => {
  return {
    type: QUESTION_LIST_SUCCESS,
    payload: data,
  }
}

export const questionListFailed = error => {
  return {
    type: QUESTION_LIST_FAILED,
    payload: error,
  }
}

// QUESTION LIST ACTIONS
export const initDailyQuizList = (data) => {
  return {
    type: INIT_DAILY_QUIZ_LIST,
    payload: data
  }
}

export const dailyQuizListSuccess = (data) => {
  return {
    type: DAILY_QUIZ_SUCCESS,
    payload: data,
  }
}

export const dailyQuizListFailed = error => {
  return {
    type: DAILY_QUIZ_FAILED,
    payload: error,
  }
}

// QUESTION CREATE ACTIONS
export const initDailyQuizCreate = (data) => {
  return {
    type: INIT_DAILY_QUIZ_CREATE,
    payload: data
  }
}

export const dailyQuizCreateSuccess = (data) => {
  return {
    type: DAILY_QUIZ_CREATE_SUCCESS,
    payload: data,
  }
}

export const dailyQuizCreateFailed = error => {
  return {
    type: DAILY_QUIZ_CREATE_FAILED,
    payload: error,
  }
}