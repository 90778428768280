import {
  INIT_QUESTION_LIST,
  QUESTION_SUCCESS,
  API_ERROR,
  INIT_QUESTION_ADD,
  QUESTION_ADD_SUCCESS,
  QUESTION_ADD_FAILED,
  INIT_QUESTION_UPDATE,
  QUESTION_UPDATE_SUCCESS,
  QUESTION_UPDATE_FAILED,
} from "./actionTypes"

const initialState = {
  questionListState: { error: false, loading: false, data: null },
  questionAddState: { error: false, loading: false, data: null },
  questionUpdateState: { error: false, loading: false, data: null }
}

const question = (state = initialState, action) => {
  switch (action.type) {
    case INIT_QUESTION_LIST:
      return {
        ...state,
        questionListState: {
          ...state.questionListState,
          loading: true,
          error: false,
          data: null
        }
      };
    case QUESTION_SUCCESS:
      return {
        ...state,
        questionListState: {
          ...state.questionListState,
          loading: false,
          data: action.payload,
          error: false
        }
      };
    case API_ERROR:
      return {
        ...state,
        questionListState: {
          ...state.questionListState,
          error: action.payload,
          loading: false,
          data: null
        }
      };
    case INIT_QUESTION_ADD:
      return {
        ...state,
        questionAddState: {
          ...state.questionAddState,
          loading: true,
          error: false,
          data: null
        }
      };
    case QUESTION_ADD_SUCCESS:
      return {
        ...state,
        questionAddState: {
          ...state.questionAddState,
          loading: false,
          data: action.payload,
          error: false
        }
      };
    case QUESTION_ADD_FAILED:
      return {
        ...state,
        questionAddState: {
          ...state.questionAddState,
          error: action.payload,
          loading: false,
          data: null
        }
      };
      case INIT_QUESTION_UPDATE:
      return {
        ...state,
        questionUpdateState: {
          ...state.questionUpdateState,
          loading: true,
          error: false,
          data: null
        }
      };
    case QUESTION_UPDATE_SUCCESS:
      return {
        ...state,
        questionUpdateState: {
          ...state.questionUpdateState,
          loading: false,
          data: action.payload,
          error: false
        }
      };
    case QUESTION_UPDATE_FAILED:
      return {
        ...state,
        questionUpdateState: {
          ...state.questionUpdateState,
          error: action.payload,
          loading: false,
          data: null
        }
      };
    default:
      return state
  }
}

export default question;
