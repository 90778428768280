export const INIT_QUESTION_LIST = "INIT_QUESTION_LIST";
export const QUESTION_SUCCESS = "QUESTION_SUCCESS";
export const API_ERROR = "QUESTION_API_ERROR";


export const INIT_QUESTION_ADD = "INIT_QUESTION_ADD";
export const QUESTION_ADD_SUCCESS = "QUESTION_ADD_SUCCESS";
export const QUESTION_ADD_FAILED = "QUESTION_ADD_FAILED";

export const INIT_QUESTION_UPDATE = "INIT_QUESTION_UPDATE";
export const QUESTION_UPDATE_SUCCESS = "QUESTION_UPDATE_SUCCESS";
export const QUESTION_UPDATE_FAILED = "QUESTION_UPDATE_FAILED";