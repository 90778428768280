export const INIT_QUESTION_SET_LISt = "INIT_QUESTION_SET_LIST";
export const QUESTION_SET_LIST_SUCCESS = "QUESTION_SET_LIST_SUCCESS";
export const QUESTION_SET_LIST_FAILED = "QUESTION_SET_LIST_FAILED";


export const INIT_QUESTION_SET_LIST_ON_MASTER = "INIT_QUESTION_SET_LIST_ON_MASTER";
export const MASTER_QUESTION_SET_LIST_SUCCESS = "MASTER_QUESTION_SET_LIST_SUCCESS";
export const MASTER_QUESTION_SET_LIST_FAILED = "MASTER_QUESTION_SET_LIST_FAILED";

export const INIT_QUESTION_SET_ADD = "INIT_QUESTION_SET_ADD";
export const MASTER_QUESTION_SET_ADD_SUCCESS = "MASTER_QUESTION_SET_ADD_SUCCESS";
export const MASTER_QUESTION_SET_ADD_FAILED = "MASTER_QUESTION_SET_ADD_FAILED";

export const INIT_QUESTION_SET_UPDATE = "INIT_QUESTION_SET_UPDATE";
export const MASTER_QUESTION_SET_UPDATE_SUCCESS = "MASTER_QUESTION_SET_UPDATE_SUCCESS";
export const MASTER_QUESTION_SET_UPDATE_FAILED = "MASTER_QUESTION_SET_UPDATE_FAILED";