import {
  INIT_REGISTRATION_LIST,
  REGISTRATION_LIST_FAILED,
  REGISTRATION_LIST_SUCCESS
} from "./actionTypes"

const initialState = {
  registrationList: { error: null, loading: false, data: [] }
}

export default function registrationReducer(state = initialState, action) {
  switch (action.type) {
    //Quiz List Case
    case INIT_REGISTRATION_LIST:
      return {
        ...state,
        registrationList: { error: null, loading: true, data: [] },
      }
    case REGISTRATION_LIST_SUCCESS:
      return {
        ...state,
        registrationList: {
          ...state.registrationList,
          loading: false,
          data: action.payload
        }
      }
    case REGISTRATION_LIST_FAILED:
      return {
        ...state,
        registrationList: {
          ...state.registrationList,
          error: action.payload,
          loading: false
        }
      }
    default:
      return { ...state }
  }
}