import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Spinner, Badge, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, FormFeedback, Collapse, ListGroup, ListGroupItem } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { initDailyQuizCreate, initDailyQuizList, initQuestionList, initQuestionSetList } from "store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";

import classnames from "classnames";

const DailyQuiz = () => {
    const dispatch = useDispatch();
    const { Quiz, MasterSet } = useSelector(state => {
        return state;
    });
    const { dailyQuizList, dailyQuizCreate, questionList } = Quiz || null;
    const { masterSetList } = MasterSet || null;

    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    });
    const [modal_large, setmodal_large] = useState(false);
    const [addMode, setAddMode] = useState(true);
    const [questions, setQuestion] = useState([]);
    const [setId, setSetId] = useState(null);
    const [activationDate, setActivationDate] = useState(null);

    const [col1, setCol1] = useState(false);

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        dispatch(initDailyQuizList())
    }, []);

    const t_col1 = () => {
        setCol1(!col1);
    }

    // const openQuizUpdateModal = (quizId) => {
    //     dispatch(initQuizView({ quizId, type: "edit" }));
    //     setmodal_large(!modal_large);
    //     removeBodyCss();
    //     setAddMode(false);
    // }

    const copyLink = (hashString) => {
        const copyURL = "https://kanhacomputeracademy.com/quiz/start?qid="+hashString
        navigator.clipboard.writeText(copyURL);
        alert("Link Copied");
    }

    useEffect(() => {
        if (dailyQuizList.data.quiz?.length) {
            const modifiedQuizData = dailyQuizList.data.quiz?.map(e => {
                const getDate = new Date(+e["activationDate"]).getDate();
                const getMonth = new Date(+e["activationDate"]).getMonth() + 1;
                const currentDate = new Date().getDate();
                const currentMonth = new Date().getMonth() + 1;
                let isActive = (getDate >= currentDate && getMonth >= currentMonth) ? true : false;
                
                e["activationDate"] = (isActive ? <Badge style={{ fontSize: 13 }} className="bg-success">
                    Active - {new Date(+e["activationDate"]).toLocaleDateString()}
                </Badge> : <Badge className="bg-danger" style={{ fontSize: 13 }}>
                    Expired - {new Date(+e["activationDate"]).toLocaleDateString()}
                </Badge>);
                e['createdAt'] = new Date(e["createdAt"]).toLocaleString()
                e['updatedAt'] = new Date(e["updatedAt"]).toLocaleString()
                e["hashString"] = <div>
                    {isActive && 
                    <Button className="btn btn-sm btn-info" onClick={() => copyLink(e["hashStr"])}>Copy Link</Button> }
                    {!isActive && <Button className="btn btn-sm btn-info" disabled>Expired</Button>}
                </div>
                return e;
            });
            setTableData({
                columns: [{
                    label: "Set Name",
                    field: "setName",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Active/Expired",
                    field: "activationDate",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Created At",
                    field: "createdAt",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Updated At",
                    field: "updatedAt",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Quiz URL",
                    field: "hashString",
                    sort: "asc",
                    width: 150,
                }],
                rows: modifiedQuizData
            })
        }
    }, [dailyQuizList?.data?.quiz]);

    const tog_large = () => {
        setmodal_large(!modal_large);
        removeBodyCss();
        setAddMode(true);
        if (!modal_large) { setQuestion([]); dispatch(initQuestionSetList({ listtype: "menu" })) }
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
        },
        validationSchema: Yup.object({

        }),
        onSubmit: (values) => {
            if (!activationDate) alert("Please select an start date")
            else if (!setId) alert("Please select a set")
            else if (!questions.length) alert("Please select questions")
            else dispatch(initDailyQuizCreate({ setId, questionIds: questions, activationDate }))
        }
    });

    // useEffect(()=>{
    //     if(dailyQuizList.data.quiz?.id){
    //         validation.setValues({
    //             setName: Quiz.quizView.data.quiz.quizTitle,
    //             activationDate: Quiz.quizView.data.quiz.description,
    //             targetCourse: Quiz.quizView.data.quiz.targetCourse,
    //             isPublished: Quiz.quizView.data.quiz.isPublished,
    //             status: Quiz.quizView.data.quiz.status,
    //             questions: Quiz.quizView.data.quiz.questions
    //         })
    //     }
    // }, [Quiz.quizView.data.quiz])

    useEffect(() => {
        if (dailyQuizCreate?.error) {
            alert(dailyQuizCreate?.error)
        }
    }, [dailyQuizCreate?.error])

    useEffect(() => {
        if (dailyQuizCreate?.data?.statusCode === 200) {
            alert(dailyQuizCreate?.data.message);
            tog_large();
            dispatch(initDailyQuizList())
        }
    }, [dailyQuizCreate?.data])

    const onChangeQuestion = (e, id) => {
        let checkedQuestions = []
        if (e.target.checked)      //if checked (true), then add this id into checkedList
        {
            checkedQuestions = questions.filter(CheckedId =>
                CheckedId !== id
            )
            checkedQuestions.push(id)
        }
        else                    //if not checked (false), then remove this id from checkedList
        {
            checkedQuestions = questions.filter(CheckedId =>
                CheckedId !== id
            )
        }
        setQuestion(checkedQuestions);
    }
    const onChangeSet = (e) => {
        setSetId(+e.target.value)
        dispatch(initQuestionList({ listtype: "questions", setId: +e.target.value }));
    }

    const onChangeActivationDate = (e) => {
        setActivationDate(new Date(e.target.value).valueOf())
    }

    const deactiveLastDatesOnCalender = () => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();

        var minDate = year + '-' + month + '-' + day;
        return minDate;
    }

    document.title = "Daily Quiz | Admin | Kanha Computer Academy";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between">
                        <Breadcrumbs title="Quiz List" />
                        <Button className="waves-effect waves-light btn btn-dark" onClick={tog_large}>Add New Quiz</Button>
                    </div>

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {dailyQuizList.loading &&
                                        <div className="text-center">
                                            <div className="p-5 d-flex justify-content-center">
                                                <Spinner className="ms-2" color="dark" />
                                            </div>
                                            <div>Please wait while loading data...</div>
                                        </div>
                                    }
                                    {!dailyQuizList.loading &&
                                        <MDBDataTable noBottomColumns responsive bordered data={tableData} />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal isOpen={modal_large} toggle={tog_large} size="lg" scrollable>
                <ModalHeader className="mt-0" toggle={tog_large}>{addMode ? "Add New Quiz" : "Edit Quiz"}</ModalHeader>
                <ModalBody>
                    <Form
                        className="row mt-2 g-3 needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Label for="startDate">
                                        Select Start Date
                                    </Label>
                                    <Input
                                        type="date"
                                        min={deactiveLastDatesOnCalender()}
                                        onChange={onChangeActivationDate}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.activationDate &&
                                                validation.errors.activationDate
                                                ? true
                                                : false
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <Label for="questionSet">
                                        Select a set
                                    </Label>
                                    <Input
                                        id="questionSet"
                                        name="setId"
                                        type="select"
                                        onChange={onChangeSet}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.setId &&
                                                validation.errors.setId
                                                ? true
                                                : false
                                        }
                                    >
                                        <option value="">Select a set</option>
                                        {masterSetList?.data?.sets?.map(e => {
                                            return (
                                                <option key={e.id} value={e.id}>{e.setName}</option>
                                            )
                                        })}
                                    </Input>
                                    {validation.touched.setId &&
                                        validation.errors.setId ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.setId}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                <div className="accordion" id="accordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className={classnames({
                                                collapsed: col1 === false
                                            }, "accordion-button fw-semibold border")} type="button" onClick={t_col1} style={{ cursor: "pointer", backgroundColor: "#eee" }}>
                                                Add Questions
                                            </button>
                                        </h2>

                                        <Collapse isOpen={col1} className="accordion-collapse">
                                            <div className="accordion-body p-0 border">

                                                <ListGroup flush>
                                                    {questionList.loading && <ListGroupItem
                                                        disabled
                                                        href="#"
                                                        tag="a"
                                                    >
                                                        Loading...
                                                    </ListGroupItem>}
                                                    {(!questionList.loading && questionList?.data?.questions?.length == 0) && <ListGroupItem

                                                        tag="a"
                                                    >
                                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                                            <div className="text-danger">Questions are not available for selected set.</div>
                                                            <br />
                                                            <Button onClick={() => window.open('/questions?action=add', '_blank')}>Add Question</Button>
                                                        </div>
                                                    </ListGroupItem>}
                                                    {(!questionList.loading) &&
                                                        questionList.data.questions?.map(elem => {
                                                            return <ListGroupItem
                                                                key={elem.id}
                                                                href="#"
                                                                tag="a"
                                                            >
                                                                <div className="d-flex">
                                                                    <div>
                                                                        <Input
                                                                            id={"id_" + elem.id}
                                                                            name="check"
                                                                            type="checkbox"
                                                                            value={elem.id}
                                                                            onChange={(e) => onChangeQuestion(e, elem.id)}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <Label for={"id_" + elem.id} className="px-2 m-0">
                                                                            {elem.question}
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </ListGroupItem>
                                                        })
                                                    }
                                                </ListGroup>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="12">
                                <div className="d-flex justify-content-end">
                                    <Button
                                        type="submit"
                                        color="dark"
                                        disabled={dailyQuizCreate?.loading}
                                    >
                                        {
                                            dailyQuizCreate?.loading && <Spinner size="sm">
                                                Loading...
                                            </Spinner>
                                        }
                                        <span>
                                            {' '} {addMode ? "Create" : "Update"}
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default DailyQuiz;



/**
 * Daily Quiz Steps
 * 1. When a user opens the link, It will register the user device information on db with status complete/incomplete.
 * 2. After verifying in the database for already existance, it will start the quiz with SetName and qusetions etc.
 * 3. After completing the quiz, when user submits the question, it will fetch and score from the database.
 * 4. After that if user tried to open the same link, it will show the quiz already taken. You can start Tomorrow again.
 * 5. 
 */