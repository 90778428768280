import {
  QUESTION_SET_LIST_SUCCESS,
  QUESTION_SET_LIST_FAILED,
  INIT_QUESTION_SET_LISt,
  INIT_QUESTION_SET_LIST_ON_MASTER,
  MASTER_QUESTION_SET_LIST_SUCCESS,
  MASTER_QUESTION_SET_LIST_FAILED,
  INIT_QUESTION_SET_ADD,
  MASTER_QUESTION_SET_ADD_SUCCESS,
  MASTER_QUESTION_SET_ADD_FAILED,
  INIT_QUESTION_SET_UPDATE,
  MASTER_QUESTION_SET_UPDATE_SUCCESS,
  MASTER_QUESTION_SET_UPDATE_FAILED
} from "./actionTypes"

export const initQuestionSetList = (data) => {
  return {
    type: INIT_QUESTION_SET_LISt,
    payload: data
  }
}

export const questionSetListSuccess = (data) => {
  return {
    type: QUESTION_SET_LIST_SUCCESS,
    payload: data,
  }
}

export const questionSetListFailed = error => {
  return {
    type: QUESTION_SET_LIST_FAILED,
    payload: error,
  }
}

// Master Set List for Master Page
export const initQuestionSetListOnMasterPage = (data) => {
  return {
    type: INIT_QUESTION_SET_LIST_ON_MASTER,
    payload: data
  }
}

export const questionSetListSuccessOnMasterPage = (data) => {
  return {
    type: MASTER_QUESTION_SET_LIST_SUCCESS,
    payload: data,
  }
}

export const questionSetListFailedOnMasterPage = error => {
  return {
    type: MASTER_QUESTION_SET_LIST_FAILED,
    payload: error,
  }
}

// Master Set Add for Master Page
export const initQuestionSetAdd = (data) => {
  return {
    type: INIT_QUESTION_SET_ADD,
    payload: data
  }
}

export const questionSetAddSuccess = (data) => {
  return {
    type: MASTER_QUESTION_SET_ADD_SUCCESS,
    payload: data,
  }
}

export const questionSetAddFailed = error => {
  return {
    type: MASTER_QUESTION_SET_ADD_FAILED,
    payload: error,
  }
}

// Master Update Action
// Master Set Add for Master Page
export const initQuestionSetUpdate = (data) => {
  return {
    type: INIT_QUESTION_SET_UPDATE,
    payload: data
  }
}

export const questionSetUpdateSuccess = (data) => {
  return {
    type: MASTER_QUESTION_SET_UPDATE_SUCCESS,
    payload: data,
  }
}

export const questionSetUpdareFailed = error => {
  return {
    type: MASTER_QUESTION_SET_UPDATE_FAILED,
    payload: error,
  }
}