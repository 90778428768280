import React from "react";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";

import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import Enrolment from "pages/Enrolment/index";
import Quiz from "pages/Quiz/index";
import Questions from "pages/Questions";
import CurriculumPage from "pages/Curriculum";
import ViewCurriculumPage from "pages/Curriculum/View";
import Registration from "pages/Registration";
import MasterSetPage from "pages/MasterSet";
import DailyQuiz from "pages/DailyQuiz";

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/enrolment", component: <Enrolment /> },
  { path: "/academy-quiz", component: <Quiz /> },
  { path: "/daily-quiz", component: <DailyQuiz /> },
  { path: "/questions", component: <Questions /> },
  { path: "/curriculum", component: <CurriculumPage /> },
  { path: "/curriculum/view/:id", component: <ViewCurriculumPage /> },
  { path: "/registration", component: <Registration /> },
  { path: "/master-set", component: <MasterSetPage /> },

  // this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  { path: "*", component: <Pages404 /> }
];

export { userRoutes, authRoutes };
