export const INIT_CURRICULUM_LIST = "INIT_CURRICULUM_LIST";
export const CURRICULUM_SUCCESS = "CURRICULUM_SUCCESS";
export const CURRICULUM_FAILED = "CURRICULUM_FAILED";

export const INIT_CURRICULUM_CREATE = "INIT_CURRICULUM_CREATE";
export const CURRICULUM_CREATE_SUCCESS = "CURRICULUM_CREATE_SUCCESS";
export const CURRICULUM_CREATE_FAILED = "CURRICULUM_CREATE_FAILED";

export const INIT_CURRICULUM_VIEW = "INIT_CURRICULUM_VIEW";
export const CURRICULUM_VIEW_SUCCESS = "CURRICULUM_VIEW_SUCCESS";
export const CURRICULUM_VIEW_FAILED = "CURRICULUM_VIEW_FAILED";

export const INIT_CURRICULUM_UPDATE = "INIT_CURRICULUM_UPDATE";
export const CURRICULUM_UPDATE_SUCCESS = "CURRICULUM_UPDATE_SUCCESS";
export const CURRICULUM_UPDATE_FAILED = "CURRICULUM_UPDATE_FAILED";