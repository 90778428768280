import {
  INIT_CURRICULUM_LIST,
  CURRICULUM_SUCCESS,
  CURRICULUM_FAILED,
  INIT_CURRICULUM_CREATE,
  CURRICULUM_CREATE_SUCCESS,
  CURRICULUM_CREATE_FAILED,
  INIT_CURRICULUM_VIEW,
  CURRICULUM_VIEW_SUCCESS,
  CURRICULUM_VIEW_FAILED,
  INIT_CURRICULUM_UPDATE,
  CURRICULUM_UPDATE_SUCCESS,
  CURRICULUM_UPDATE_FAILED
} from "./actionTypes"

// List Actions
export const initMasterCourseCurriculumList = (data) => {
  return {
    type: INIT_CURRICULUM_LIST,
    payload: data
  }
}

export const curriculumListSuccess = (data) => {
  return {
    type: CURRICULUM_SUCCESS,
    payload: data,
  }
}

export const curriculumListFailed = error => {
  return {
    type: CURRICULUM_FAILED,
    payload: error,
  }
}

// Create Curriculum
export const initCourseCurriculumCreate = (data) => {
  return {
    type: INIT_CURRICULUM_CREATE,
    payload: data
  }
}

export const curriculumCreateSuccess = (data) => {
  return {
    type: CURRICULUM_CREATE_SUCCESS,
    payload: data,
  }
}

export const curriculumCreateFailed = error => {
  return {
    type: CURRICULUM_CREATE_FAILED,
    payload: error,
  }
}

// View Curriculum
export const initCourseCurriculumView = (data) => {
  return {
    type: INIT_CURRICULUM_VIEW,
    payload: data
  }
}

export const curriculumViewSuccess = (data) => {
  return {
    type: CURRICULUM_VIEW_SUCCESS,
    payload: data,
  }
}

export const curriculumViewFailed = error => {
  return {
    type: CURRICULUM_VIEW_FAILED,
    payload: error,
  }
}

// Edit Curriculum
export const initCourseCurriculumEdit = (data) => {
  return {
    type: INIT_CURRICULUM_UPDATE,
    payload: data
  }
}

export const curriculumEditSuccess = (data) => {
  return {
    type: CURRICULUM_UPDATE_SUCCESS,
    payload: data,
  }
}

export const curriculumEditFailed = error => {
  return {
    type: CURRICULUM_UPDATE_FAILED,
    payload: error,
  }
}