import {
  INIT_QUESTION_LIST,
  API_ERROR,
  QUESTION_SUCCESS,
  INIT_QUESTION_ADD,
  QUESTION_ADD_SUCCESS,
  QUESTION_ADD_FAILED,
  INIT_QUESTION_UPDATE,
  QUESTION_UPDATE_SUCCESS,
  QUESTION_UPDATE_FAILED,
} from "./actionTypes"

export const initMasterQuestionList = (data) => {
  return {
    type: INIT_QUESTION_LIST,
    payload: data
  }
}

export const questionMasterListSuccess = (data) => {
  return {
    type: QUESTION_SUCCESS,
    payload: data,
  }
}

export const questionMasterListFailed = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

// Add Quetion Action
export const initQuestionAdd = (data) => {
  return {
    type: INIT_QUESTION_ADD,
    payload: data
  }
}

export const questionAddSuccess = (data) => {
  return {
    type: QUESTION_ADD_SUCCESS,
    payload: data,
  }
}

export const questionAddFailed = error => {
  return {
    type: QUESTION_ADD_FAILED,
    payload: error,
  }
}

// Edit Question Action
export const initQuestionUpdate = (data) => {
  return {
    type: INIT_QUESTION_UPDATE,
    payload: data
  }
}

export const questionUpdateSuccess = (data) => {
  return {
    type: QUESTION_UPDATE_SUCCESS,
    payload: data,
  }
}

export const questionUpdateFailed = error => {
  return {
    type: QUESTION_UPDATE_FAILED,
    payload: error,
  }
}