import { call, put, takeEvery } from "redux-saga/effects";

// Enrolment Redux States
import { INIT_QUESTION_ADD, INIT_QUESTION_LIST, INIT_QUESTION_UPDATE } from "./actionTypes";
import { questionAddFailed, questionAddSuccess, questionMasterListFailed, questionMasterListSuccess, questionUpdateFailed, questionUpdateSuccess } from "./actions";

//Include Both Helper File with needed methods
import {
  addNewQuestion,
  getQuestionMasterList,
  updateQuestion
} from "../../helpers/backend_helper";

function* questionList() {
  try {
    const response = yield call(getQuestionMasterList);
    if (response && response.statusCode == 200) {
      yield put(questionMasterListSuccess(response));
    } else {
      yield put(questionMasterListFailed(response.message));
    }
  } catch (error) {
    yield put(questionMasterListFailed(error));
  }
}

function* questionAdd({ payload }) {
  try {
    const response = yield call(addNewQuestion, {
      data: {
        question: payload.question,
        optionA: payload.optionA,
        optionB: payload.optionB,
        optionC: payload.optionC,
        optionD: payload.optionD,
        correctAnswer: +payload.correctAnswer,
        explanation: payload.explanation,
        tags: payload.tags,
        forDailyQuiz: payload.forDailyQuiz,
        setId: payload.setId == "" ? null : +payload.setId
      }
    });
    if (response && response.statusCode == 200) {
      yield put(questionAddSuccess(response));
    } else {
      yield put(questionAddFailed(response.message));
    }
  } catch (error) {
    yield put(questionAddFailed(error));
  }
}

function* questionUpdate({ payload }) {
  try {
    const response = yield call(updateQuestion, {
      data: {
        question: payload.question,
        optionA: payload.optionA,
        optionB: payload.optionB,
        optionC: payload.optionC,
        optionD: payload.optionD,
        correctAnswer: +payload.correctAnswer,
        explanation: payload.explanation,
        tags: payload.tags
      },
      questionId: payload.questionId
    });
    if (response && response.statusCode == 200) {
      yield put(questionUpdateSuccess(response));
    } else {
      yield put(questionUpdateFailed(response.message));
    }
  } catch (error) {
    yield put(questionUpdateFailed(error));
  }
}

function* questionSaga() {
  yield takeEvery(INIT_QUESTION_LIST, questionList);
  yield takeEvery(INIT_QUESTION_ADD, questionAdd);
  yield takeEvery(INIT_QUESTION_UPDATE, questionUpdate);
}

export default questionSaga;