import {
  INIT_CURRICULUM_LIST,
  CURRICULUM_SUCCESS,
  CURRICULUM_FAILED,
  INIT_CURRICULUM_VIEW,
  CURRICULUM_VIEW_SUCCESS,
  CURRICULUM_VIEW_FAILED,
  INIT_CURRICULUM_UPDATE,
  CURRICULUM_UPDATE_SUCCESS,
  CURRICULUM_UPDATE_FAILED
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
  data: []
}

const curriculum = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CURRICULUM_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case CURRICULUM_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload
      }
      break
    case CURRICULUM_FAILED:
      state = { ...state, error: action.payload, loading: false }
      break
    case INIT_CURRICULUM_VIEW:
      state = {
        ...state,
        loading: true,
      }
      break
    case CURRICULUM_VIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        data: action.payload
      }
      break
    case CURRICULUM_VIEW_FAILED:
      state = { ...state, error: action.payload, loading: false }
      break
      case INIT_CURRICULUM_UPDATE:
        state = {
          ...state,
          loading: true,
        }
        break
      case CURRICULUM_UPDATE_SUCCESS:
        state = {
          ...state,
          loading: false,
          data: action.payload
        }
        break
      case CURRICULUM_UPDATE_FAILED:
        state = { ...state, error: action.payload, loading: false }
        break
    default:
      state = { ...state }
      break
  }
  return state
}

export default curriculum;
